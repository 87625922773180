import axios from "axios";

import { config } from "../config/config";

const packageJson = require("../../package.json");

export const invokeApi = async (url, params, cookies) => {
  try {
    let headers = {
      "Content-Type": "application/json",

      appversion: packageJson.version,

      platform: "web",
    };

    if (
      cookies &&
      cookies[config.cookieName] &&
      cookies[config.cookieName].token &&
      cookies[config.cookieName].loginUserId
    ) {
      headers.Authorization = "Bearer " + cookies[config.cookieName].token;

      headers.loginUserId = cookies[config.cookieName].loginUserId;
    }

    if (
      cookies &&
      cookies[config.sessionCookie] &&
      cookies[config.sessionCookie].sessionId
    ) {
      headers.sessionId = cookies[config.sessionCookie].sessionId;
    }

    return await axios.post(url, params, { headers: headers });
  } catch ({ response }) {
    return response;
  }
};

export const invokeFormDataApi = async (url, formData, cookies) => {
  try {
    let headers = {
      "Content-Type": "multipart/form-data",
      appversion: packageJson.version,
      platform: "web",
    };
    if (
      cookies &&
      cookies[config.cookieName] &&
      cookies[config.cookieName].token &&
      cookies[config.cookieName].loginUserId
    ) {
      headers.Authorization = "Bearer " + cookies[config.cookieName].token;
      headers.loginUserId = cookies[config.cookieName].loginUserId;
    }
    if (
      cookies &&
      cookies[config.sessionCookie] &&
      cookies[config.sessionCookie].sessionId
    ) {
      headers.sessionId = cookies[config.sessionCookie].sessionId;
    }
    return await axios.post(url, formData, { headers: headers });
  } catch ({ response }) {
    return response;
  }
};

export const apiList = {
  // traffic tracking
  addWebTraffic: "/web/addWebTraffic",

  userLogin: "/user/login",

  //add user api
  userAdd: "/user/addUser",

  //display user data
  getUsers: "/user/getUsers",

  //view
  getUser: "/user/getUser",

  //update user api and delete api
  updateUser: "/user/updateUser",

  // deleteUser
  deleteUser: "/user/deleteUser",

  //change password api
  changePassword: "/user/changePassword",

  //update roles

  updateUserRoles: "/user/updateUserRoles",

  //addLead
  addLead: "/leads/addLead",

  // getLeads
  getLeads: "/leads/getLeads",

  //getLead
  getLead: "/leads/getLead",

  //updateLead
  updateLead: "/leads/updateLead",

  //addcollege
  addCollege: "/college/addCollege",

  //getColleges
  getColleges: "/college/getColleges",

  // getCollege
  getCollege: "/college/getCollege",

  // updateCollege
  updateCollege: "/college/updateCollege",

  // deleteCollege
  deleteCollege: "/college/deleteCollege",

  // addCourse
  addCourse: "/course/addCourse",

  // getCourses
  getCourses: "/course/getCourses",

  //getCourse
  getCourse: "/course/getCourse",

  // updateCourse
  updateCourse: "/course/updateCourse",

  // deleteCourse
  deleteCourse: "/course/deleteCourse",

  //addLeadTrack
  addLeadTrack: "/leads/addLeadTrack",

  //getLeadTrack
  getLeadTrack: "/leads/getLeadTrack",

  //get followup data
  getFollowupLeads: "/leads/getFollowupData",

  //get fresh leads for l1
  getFreshData: "/leads/getFreshData",

  //get fresh leads for l1
  getLeadTrackings: "/leads/getLeadTrackings",

  //get locations data
  getLocations: "/location/getLocations",

  //add locations data
  addLocation: "/location/addLocation",

  //get location data
  getLocation: "/location/getLocation",

  //update location
  updateLocation: "/location/updateLocation",

  //delete location
  deleteLocation: "/location/deleteLocation",

  //add branch
  addBranch: "/branch/addBranch",

  //getbranches

  getBranches: "/branch/getBranches",

  //getBranch

  getBranch: "/branch/getBranch",

  //updateBranch
  updateBranch: "/branch/updateBranch",

  //deleteBranch
  deleteBranch: "/branch/deleteBranch",

  //visiting leads
  visitedLeads: "/leads/getVisitedLeads",

  //addFeeStructure
  addFeeStructure: "/feeStructure/addFeeStructure",

  //getFeeStructureData
  getFeeStructureData: "/feeStructure/getFeeStructureData",

  // getFeeStructure
  getFeeStructure: "/feeStructure/getFeeStructure",

  // get all FeeStructure
  getAllFeeStructure: "/feeStructure/getFeeStructures",

  // get all FeeStructure
  getFeesStructures: "/feeStructure/getFeesStructures",

  // updateFeeStructure
  updateFeeStructure: "/feeStructure/updateFeeStructure",

  // deleteFeeStructure
  deleteFeeStructure: "/feeStructure/deleteFeeStructure",

  //getConversionAnalytics
  getConversionAnalytics: "/analytics/getConversionAnalytics",

  //getFailureAnalytics
  getFailureAnalytics: "/analytics/getFailureAnalytics",

  //getDataCoverage
  getDataCoverage: "/analytics/getDataCoverage",

  //getTeamCalls
  getTeamCalls: "/analytics/getTeamCalls",

  //getCollegeAdmissions
  getCollegeAdmissions: "/analytics/getCollegeAdmissions",

  //getGraphData
  getGraphData: "/analytics/getGraphData",

  //getCurrentLeadTracking
  getCurrentLeadTracking: "/analytics/getCurrentLeadTracking",

  //getCallAverageDifference
  getCallAverageDifference: "/analytics/getCallAverageDifference",

  //getMyCollegeAnalytics
  getMyCollegeAnalytics: "/analytics/getMyCollegeAnalytics",

  //addAlert
  addAlert: "/alert/addAlert",

  //getAlerts
  getAlerts: "/alert/getAlerts",

  //deleteAlert
  deleteAlert: "/alert/deleteAlert",

  //getAlert
  getAlert: "/alert/getAlert",

  //updateAlert
  updateAlert: "/alert/updateAlert",

  //get assigned leads
  getAssignedLeads: "/leads/getAssignedLeads",

  //get hot leads
  getHotLeads: "/leads/getHotLeads",

  //get warm leads
  getWarmLeads: "/leads/getWarmLeads",

  //get cold leads
  getColdLeads: "/leads/getColdLeads",

  //get visiting leads
  getVisitingLeads: "/leads/getVisitingLeads",

  //get college wise leads
  getCollegeWiseData: "/leads/getCollegeWiseData",

  // new api's
  getAllAssignedLeads: "/leads/getAllAssignedLeads",

  getAllLevelThreeLeads: "/leads/getAllLevelThreeLeads",

  updateAssignedLead: "/leads/updateAssignedLead",

  getAssignedLead: "/leads/getAssignedLead",

  addOfficeAdmission: "/officeAdmission/addOfficeAdmission",

  getOfficeAdmissions: "/officeAdmission/getAllOfficeAdmissions",

  getOfficeAdmission: "/officeAdmission/getOfficeAdmission",

  updateOfficeAdmission: "/officeAdmission/updateOfficeAdmission",
};

